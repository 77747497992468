import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { ReactNode, useState } from 'react'

import Button from '~/components/buttons/Button'
import { Item } from '~/types/data-array'
import { cx } from '~/utils'

import * as containerStyles from './CaseStudyClientVideoReview.module.scss'

interface CaseStudyClientVideoReviewProps {
  color: string
  link: string
  imageTitle: string
  name: ReactNode
  clientImage: IGatsbyImageData | undefined
  review: Item[]
  logo?: string
  bgRings?: boolean
  bgRingsClass?: string
  bgRingImage?: string
}

const CaseStudyClientVideoReview = ({
  color,
  link,
  logo,
  name,
  review,
  clientImage,
  imageTitle,
  bgRings,
  bgRingsClass,
  bgRingImage,
}: CaseStudyClientVideoReviewProps) => {
  const [showVideo, setShowVideo] = useState(false)

  const handleChange = () => {
    setShowVideo(() => true)
  }

  return (
    <div className={containerStyles.caseStudyVideoReview}>
      <div className="container case-wrapper-xl">
        <div className="row">
          <div className="col-12">
            <div className={containerStyles.caseStudyVideoReview__title}>
              Client review
            </div>
          </div>
        </div>

        <div
          className={cx('row', containerStyles.caseStudyVideoReview__container)}
        >
          {bgRings && (
            <div
              className={cx(
                bgRingsClass,
                containerStyles.caseStudyVideoReview__bgRings,
              )}
            >
              <img
                src={bgRingImage}
                alt={`${bgRingImage} | Codica`}
                title={bgRingImage}
                loading="lazy"
              />
            </div>
          )}

          <div className="col-lg-6">
            <div className={containerStyles.caseStudyVideoReview__clientImage}>
              {!showVideo && (
                <>
                  <GatsbyImage
                    image={clientImage as IGatsbyImageData}
                    alt={`${imageTitle} | Codica`}
                    title={imageTitle}
                  />
                  <Button
                    type="button"
                    name="Play video"
                    onClick={handleChange}
                    onKeyDown={handleChange}
                    customClassName={cx(
                      containerStyles.caseStudyVideoReview__playWrapper,
                      containerStyles[
                        `caseStudyVideoReview__playWrapper_${color}`
                      ],
                    )}
                  />
                </>
              )}
              <div
                className={containerStyles.caseStudyVideoReview__videoYoutube}
              >
                {showVideo && (
                  <iframe
                    src={link}
                    title={imageTitle}
                    width="100%"
                    height="100%"
                    allowFullScreen
                    style={{ border: 0 }}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div
              className={cx(
                containerStyles.caseStudyVideoReview__wrapper,
                containerStyles[`caseStudyVideoReview__wrapper_${color}`],
              )}
            >
              {logo && (
                <div className={containerStyles.caseStudyVideoReview__logo}>
                  <img
                    src={logo}
                    alt="Logo video review | Codica"
                    title="Logo video review"
                    loading="lazy"
                  />
                </div>
              )}
              <div className={containerStyles.caseStudyVideoReview__name}>
                {name}
              </div>
              {review.map(({ item }) => (
                <div
                  key={String(item)}
                  className={containerStyles.caseStudyVideoReview__text}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CaseStudyClientVideoReview
