// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bM_kr";
export var caseStudyBackground__lineColor = "bM_kp";
export var caseStudyHead__imageWrapper = "bM_kk";
export var caseStudyProjectsSection = "bM_ks";
export var caseStudyQuote__bgRing = "bM_km";
export var caseStudySolution__ring = "bM_ky";
export var caseStudySolution__ringThree = "bM_mm";
export var caseStudySolution__ringTwo = "bM_kz";
export var caseStudyVideo__ring = "bM_kv";
export var caseStudy__bgDark = "bM_kh";
export var caseStudy__bgLight = "bM_kg";
export var caseStudy__bgLightGray = "bM_ml";